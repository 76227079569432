<template>
  <div class="section-title" :class="{'section-title--no-margin': noMargin}">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: 'TheTitle',
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.87)',
      required: false
    },
    noMargin: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style  lang="scss">
.section-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .title-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    button {
      padding: 0;
      border: none;
      background: none;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      &.red {
        color: darkred;
      }
      &.green {
        color: green;
      }
    }
  }
  p {
    font-size: 18px;
    font-weight: 700;
    &.red {
      color: darkred;
    }
    &.green {
      color: green;
    }
  }
  & h1,h2,h3,h4,h5,h6 {
    color: v-bind('color');
  }

  & h3,h3,h4,h5,h6 {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &--no-margin h1 {
    margin-bottom: 0;
  }
  &--no-margin h2 {
    margin-bottom: 0;
  }
  &--no-margin h3 {
    margin-bottom: 0;
  }
  &--no-margin h4 {
    margin-bottom: 0;
  }
}
</style>
