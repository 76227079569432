import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import 'bootstrap'
import Toaster from '@meforma/vue-toaster'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import VueAwesomePaginate from 'vue-awesome-paginate'
import VueClickAway from 'vue3-click-away'
import { getCookie } from '@/utilits/getCookie'

dayjs.extend(customParseFormat)
const baseUrl = 'https://sfa4api.wfstudio.ru'

axios.defaults.baseURL = baseUrl

const api = axios.create({
  baseURL: 'https://sfa4api.wfstudio.ru',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
api.interceptors.request.use((config) => {
  const token = getCookie('user_token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default api

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(VueClickAway)
app.use(VueAwesomePaginate)
app.provide('dayjs', dayjs)
app
  .use(Toaster, { position: 'top-right' })
  .provide('toast', app.config.globalProperties.$toast)

if (process.env.NODE_ENV === 'development') {
  app.mount('#app')
} else {
  document.addEventListener('deviceready', function () {
    app.mount('#app')
  })
}

document.addEventListener('gesturestart', (event) => {
  event.preventDefault()
})
