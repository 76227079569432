<template>
  <header class="header">
    <div class="header__container">
      <div class="header__logo">
        <router-link to="/">
          <img alt="Логотип сайта" src="@/assets/uploads/icon/logo.svg">
        </router-link>
      </div>
      <nav class="header__nav">
        <ul class="header__list">
          <li class="header__item">
            <router-link
              v-wave
              class="header__link"
              to="/contacts"
            >
              КОНТАКТЫ
            </router-link>
          </li>
          <li class="header__item">
            <router-link v-wave class="header__link" to="/calls">
              ЗВОНКИ
            </router-link>
          </li>
          <li class="header__item">
            <div class="dropdown">
              <a class="dropdown-toggle"
                 data-bs-toggle="dropdown"
                 aria-expanded="false"
              >
                ВИЗИТЫ
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link v-wave class="header__link" to="/visit-fact">
                    Факт
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/visit-plan">
                    План
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="header__item">
            <router-link v-wave class="header__link" to="/clients">
              КЛИЕНТЫ
            </router-link>
          </li>
          <li class="header__item">
            <router-link v-wave class="header__link" to="/offers">
              ПРЕДЛОЖЕНИЯ
            </router-link>
          </li>
          <li class="header__item">
            <div class="dropdown">
              <a class="dropdown-toggle"
                 data-bs-toggle="dropdown"
                 aria-expanded="false"
              >
                ОТЧЕТЫ
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link v-wave class="header__link" to="/reports/kpi">
                    KPI
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/reports/bpc">
                    Параметры АКБ
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/reports/debt">
                    Дебиторка
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/reports/charts">
                    Аналитика SFA
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/reports/visits-map">
                    Карта визитов и точек продаж
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/reports/points-map">
                    Карта точек продаж
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="header__item">
            <div class="dropdown">
              <a class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
              >
                ЗАДАЧИ
              </a>
              <ul class="dropdown-menu">
                <li v-if="hasRights">
                  <router-link v-wave class="header__link" to="/task/add">
                    Добавить задачу
                  </router-link>
                </li>
                <li>
                  <router-link v-wave class="header__link" to="/task">
                    Список
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="header__item">
            <div class="dropdown">
              <a class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
              >
                {{ userFullName }}
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" @click.prevent="logOut">Выйти</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="openModal" v-if="usersList">Сменить позицию</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="resetUserPosition" v-if="userPosition && (user.position_id !== userPosition)">Войти как {{user.username}}</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div class="header__mobile container">
      <router-link class="header__profile-mobile" to="/profile">
        {{ userFullName }}
      </router-link>

      <HamburgerButton/>
    </div>
  </header>
  <ChangePositionModal :modal-is-open="modalIsOpen" @modal-close="modalIsOpen = false" :users="usersList" v-if="usersList" />
</template>

<script>
import HamburgerButton from '@/components/layout/header/HamburgerButton.vue'
import { useUserStore } from '@/stores/UserStore'
import { computed, onMounted, ref, toRefs } from 'vue'
import { useUsersListStore } from '@/stores/UsersListStore'
import ChangePositionModal from '@/components/layout/header/ChangePositionModal.vue'
import { storeToRefs } from 'pinia'
export default {
  name: 'TheHeader',
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: { HamburgerButton, ChangePositionModal },
  setup (props) {
    const userStore = useUserStore()
    const { user } = toRefs(props)
    const modalIsOpen = ref(false)
    const selectedUser = ref(null)
    const { userFullName, hasRights, userPosition } = storeToRefs(userStore)
    const logOut = () => {
      userStore.logOut()
    }
    const userListStore = useUsersListStore()
    const usersList = computed(() => {
      return userListStore.usersList
    })
    const openModal = () => {
      modalIsOpen.value = true
    }
    const resetUserPosition = () => {
      userStore.setUserPosition(user.value.position_id, user.value.last_name, user.value.first_name)
    }
    onMounted(() => {
      userStore.loadUserFromSession()
      userListStore.fetchUsers()
    })
    return {
      logOut,
      userFullName,
      openModal,
      modalIsOpen,
      usersList,
      selectedUser,
      hasRights,
      userPosition,
      resetUserPosition
    }
  }

}

</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  margin-bottom: 30px;
  padding: 0 16px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  z-index: 500;
}

.header__container {
  display: flex;
  align-items: center;
  @include tablets {
    display: none;
  }
}

.header__logo {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.header__logo img {
  display: block;
  width: 190px;
  height: 32px;
}

.header__nav {
}

.header__list {
  display: flex;
}

.header__item {
  font-weight: 500;
  padding: 0 5px;
}

.header__profile {
  color: #fff;
  background: #ff8330;
}

.header__link {
  display: block;
  padding: 25px 16px;
  transition: background-color .2s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  &:hover:not(&.header__profile) {
    background-color: #f6f6f6;
  }

  &.router-link-exact-active:not(&.header__profile) {
    color: #ff8330;
  }
}

::v-deep(.header-popover__links .header__link) {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 16px;
}

.header__mobile {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #FFFFFF;
  @include tablets {
    display: flex;
  }
}

.header__profile-mobile {
  border-radius: 4px;
  text-transform: uppercase;
  padding: 10px 16px;
  background-color: #ff8330;
  color: #FFFFFF;
}
.dropdown {
  height: 100%;
  a {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.dropdown-menu {
  padding: 0;
  a {
    height: auto;
    padding: 10px;
  }
}
.dropdown-toggle:after {
  display: none;
}
</style>
